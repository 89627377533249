import React from 'react';

const h3 = ({children}) => {
    return (
        <h3 className="text-xl font-bold uppercase mb-4">
            {children}
        </h3>
    );
};

export default h3;