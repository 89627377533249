import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FadeIn from '../components/fadein'

import H3 from '../components/h3'



const IndexPage = () => {
    return (
      <Layout>
        <Seo title="Home" />
        <FadeIn>
        <div className="container mx-auto mb-8">
          <article className="main p-4 lg:w-2/3 ">

            <H3>About</H3>
            <p>I'm a full-stack developer working remotely (from 2008) for a UK company <a className="underline" href="https://treaclemedia.com">Treaclemedia ltd.</a> I'm responsible for the web development process of all the websites using various of technologies. I'm passionate about Jamstack, currently trying to fully switch to it in all of our projects using wordpress as a headless CMS. And I must say, I really enjoy what I do :)</p>

          </article>
        </div>
        </FadeIn>
        <FadeIn>
        <div className="container mx-auto mb-8">
          <article className="main p-4 lg:w-1/2">
          <H3>Technologies I use</H3>
          <div className="grid grid-cols-4 md:grid-cols-8 lg:cols-16 gap-8 grid-flow-row py-4">
            <StaticImage 
            src="../images/React_logo_logotype_emblem.png" 
            width={80} 
            height={80}
            alt="ReactJs"
            transformOptions={{'fit': 'contain'}}
             />
             <StaticImage 
            src="../images/gatsby-icon.png" 
            width={80}
            height={80} 
            alt="Gatsby"
            transformOptions={{'fit': 'contain'}}
             />

            <StaticImage 
            src="../images/1200px-Nextjs-logo.svg.png" 
            width={80}
            height={80} 
            alt="Nextjs"
            transformOptions={{'fit': 'contain'}}
             />
            <StaticImage 
            src="../images/Tailwind_CSS_Logo.svg.png" 
            width={80}
            height={80} 
            alt="Tailwind CSS"
            transformOptions={{'fit': 'contain'}}
             />

            <StaticImage 
            src="../images/alpine-js-logo-21F4169EAB-seeklogo.com.png" 
            width={80}
            height={80} 
            alt="Alpine.js"
            transformOptions={{'fit': 'contain'}}
             />
             <StaticImage 
             src="../images/1184px-Vue.js_Logo_2.svg.png" 
             width={80}
             height={80} 
             alt="Vuejs"
             transformOptions={{'fit': 'contain'}}
              />
             <StaticImage 
            src="../images/1969px-Laravel.svg.png" 
            width={80}
            height={80} 
            alt="Laravel"
            transformOptions={{'fit': 'contain'}}
             />
             <StaticImage 
             src="../images/WordPress_blue_logo.svg.png" 
             width={80}
             height={80} 
             alt="Wordpress"
             transformOptions={{'fit': 'contain'}}
              />
              <StaticImage 
              src="../images/PHP-logo.svg.png" 
              width={80}
              height={80} 
              alt="PHP"
              transformOptions={{'fit': 'contain'}}
               />

              <StaticImage 
              src="../images/MySQL-Logo.png" 
              width={80}
              height={80} 
              alt="MySQL"
              transformOptions={{'fit': 'contain'}}
               />

              <StaticImage 
              src="../images/06c62480-b95c-11ea-8b35-b5dd97d070f1.png" 
              width={80}
              height={80} 
              alt="Timber"
              transformOptions={{'fit': 'contain'}}
               />
               <StaticImage 
              src="../images/twig.png" 
              width={80}
              height={80} 
              alt="Twig"
              transformOptions={{'fit': 'contain'}}
               />
               <StaticImage 
              src="../images/icon-256x256.png" 
              width={80}
              height={80} 
              alt="Advanced Custom Fields"
              transformOptions={{'fit': 'contain'}}
               />
               
               <StaticImage 
              src="../images/Tux.svg.png" 
              width={80}
              height={80} 
              alt="Linux"
              transformOptions={{'fit': 'contain'}}
               />
               <StaticImage 
              src="../images/vertical-logo-monochromatic.png" 
              width={80}
              height={80} 
              alt="Docker"
              transformOptions={{'fit': 'contain'}}
               />
          </div>
          </article>
        </div>
        </FadeIn>
        <FadeIn>
        <div className="container mx-auto mb-8">
          <article className="main p-4 lg:w-2/3">
            <H3>Experience</H3>
            <div className="grid grid-cols-2 md:grid-cols-12 gap-4">
              <div className="md:col-span-4">
              <strong>Jan 2007 - Present</strong>
              </div>
              <div className="md:col-span-8">
              Web Developer at <a href="https://www.treaclemedia.com">Treaclemedia Ltd.</a>
              </div>
              <div className="md:col-span-4">
              <strong>Sep 2005 - Aug 2006</strong>
              </div>
              <div className="md:col-span-8">
              Graphic Designer at Workexpress.pl
              </div>
              <div className="md:col-span-4">
              <strong>Dec 2004 - Jul 2005</strong>
              </div>
              <div className="md:col-span-8">
              Graphic Designer at Digital Core Design
              </div>
              <div className="md:col-span-4">
              <strong>2002 - Dec 2004</strong>
              </div>
              <div className="md:col-span-8">
              Freelance Graphic Designer
              </div>
            </div>
          </article>
        </div>
        </FadeIn>

        <FadeIn>
        <div className="container mx-auto mb-8">
          <article className="main p-4 lg:w-2/3 ">

            <H3>Contact</H3>
            <p>Email: <a className="underline" href='m&#97;&#105;&#108;&#116;o&#58;p%&#54;1w%65lwo&#115;&#64;gm&#97;&#37;69l&#46;co&#109;'>paw&#101;l&#119;&#111;&#115;&#64;g&#109;ai&#108;&#46;com</a><br />LinkedIn: <a className="underline" href="https://www.linkedin.com/in/pawel-wos-5405665/">Click here</a></p>

          </article>
        </div>
        </FadeIn>
      </Layout>
  )
}

export default IndexPage
