import React from "react"

const FadeIn = ({className, children}) => {
  const [isVisible, setVisible] = React.useState(false)
  const domRef = React.useRef(null)
  const classes = className ? className : ""

  React.useEffect(() => {
    const element = domRef.current
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting))
    })
    observer.observe(element)
    return () => observer.unobserve(element)
  }, []);
  return (
    <div 
      ref={domRef}
      className={ classes + " transition-opacity opacity-0 ease-in-out delay-300 duration-700" + (isVisible ? ' opacity-100' : null) }
    >
      {children}
    </div>
  )
}
export default FadeIn